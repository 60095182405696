var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticStyle: { height: "100%" } }, [
    _c(
      "div",
      {
        staticClass: "d-flex grey lighten-2 align-center px-2",
        staticStyle: {
          position: "fixed",
          top: "48px",
          left: "64px",
          height: "32px",
          width: "calc(100% - 64px)",
          "z-index": "3"
        }
      },
      [
        _vm._v(" " + _vm._s(_vm.longId) + " "),
        _c("v-spacer"),
        _c(
          "v-btn",
          {
            attrs: { icon: "" },
            on: {
              click: function($event) {
                _vm.previewDialogLocal = false
              }
            }
          },
          [_c("v-icon", [_vm._v("highlight_off")])],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      { staticStyle: { "margin-top": "32px", height: "calc(100% - 32px)" } },
      [
        _c("iframe", {
          staticStyle: { width: "100%", height: "100%", border: "0px" },
          attrs: { src: _vm.pdfUrl }
        })
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }